import React, { Component } from 'react';
import VisibilitySensor from 'react-visibility-sensor';
import { Row, Col } from 'reactstrap';
import { Map as LeafletMap, TileLayer, FeatureGroup, WMSTileLayer, LayersControl, Popup } from 'react-leaflet';
import { EditControl } from 'react-leaflet-draw';
import L from 'leaflet';
import * as turf from 'turf';
import moment from 'moment';
import 'moment/locale/el';

import { openAside, closeAside } from 'core/ducks/ui/menu';
import * as roles from 'core/model/roles';
import { SecureContent } from 'core/components';
import { LayersControl as CustomLayersControl } from 'leaflet-controls';
import 'leaflet-controls/lib/leaflet-dragging';
import { QuestionnaireContext } from '../views/layout/questionnaire';
import PopupContent from './popupContent';
import { createHexColor, toWKT } from '../model/lib';
import ProfileControl from './profileControl';

const boundaryStyle = {
	color: 'blue',
	weight: 4,
	dashArray: 5,
	fillColor: 'none'
};

const createLeafletIcon = (icon) => {
	let Licon = new L.icon({
		iconUrl: icon,
		iconRetinaUrl: icon,
		iconAnchor: [10, 10],
		iconSize: [20, 20]
	});
	return Licon;
};

const layerOptions = (style) => {
	return {
		icon: createLeafletIcon(style.icon),
		color: style.color,
		fillColor: style.color,
		weight: 3,
		fillOpacity: 0.8,
		opacity: 1,
	};
}

const predefinedLayerOptions = (attribute) => {
	return {
		icon: createLeafletIcon(attribute.icon),
		color: attribute.geom_color !== '' ? attribute.geom_color : createHexColor(attribute.mname),
		weight: 5,
		fillColor: attribute.geom_color !== '' ? attribute.geom_color : createHexColor(attribute.mname),
		opacity: 0.7,
	}
}

class Map extends Component {

	constructor(props) {
		super(props);
		const { messages } = props.context;
		this.state = {
			center: JSON.parse(props.context.project.center),
			zoom: props.context.project.zoom,
			leafletPopup: null,
			predefinedEditing: false,
			whichAnswersAreShown: {
				mine: {checked: props.context.role === roles.AUTHORIZED ? true : false, name: messages['my answers']},
				all: {checked: props.context.role === roles.AUTHORIZED ? false : true, name: messages['all answers']},
				topRated: {checked: false, name: 'Top rated'},
			},
			visibleLayers: {owner: props.context.role === roles.AUTHORIZED ? true : false, attributesToHide: props.context.attributesToHide, toprated: false},
		};
		L.Draw.Polygon.prototype._getTooltipText = () => {
			return {
				text: messages['add polygon']
					+ '<br/>' + messages['close polygon']
					+ '<br/>' + messages['or esc to cancel']
			};
		};

		L.Draw.Polyline.prototype._getTooltipText = () => {
			return {
				text: messages['add polyline']
					+ '<br/>' + messages['close polyline']
					+ '<br/>' + messages['or esc to cancel']
			};
		};
		this.mapRef = null;
		this._predefinedFG = null;
		this._editableFG = null;
		this.boundaryFGRef = React.createRef();
		this.bboxEditControlRef = React.createRef();
		this.drawer = null;
	}

	componentDidMount() {
		document.addEventListener('keydown', this.stopDrawing);
	}

	componentDidUpdate(prevProps, prevState) {
		const prevContext = prevProps.context;
		const { context } = this.props;
		if (prevContext.project.center !== context.project.center || prevContext.project.zoom !== context.project.zoom) {
			this.setState({
				center: JSON.parse(context.project.center),
				zoom: context.project.zoom,
			});
			this.map = this.mapRef.leafletElement.locate();
		}

		if (prevContext.isAsideOpen !== context.isAsideOpen)
			this.mapRef.leafletElement.invalidateSize();

		if (prevContext.activeTopic !== this.context.activeTopic) {
			const leafletFG = this.boundaryFGRef.current.leafletElement;
			leafletFG.eachLayer(layer => {
				leafletFG.removeLayer(layer);
			});
			if (context.activeTopic && context.activeTopic.boundary && context.activeTopic.boundary.coordinates.length > 0) {
				let leafletGeoJSON = new L.GeoJSON(context.activeTopic.boundary);
				leafletGeoJSON.eachLayer((layer) => {
					layer.options = {
						...layer.options,
						...boundaryStyle
					}
					leafletFG.addLayer(layer);
				});
				this.mapRef.leafletElement.fitBounds(leafletGeoJSON.getBounds(), {paddingBottomRight: [0, 0]});
			}
		}

		if (!prevContext.drawOnMap && context.drawOnMap) {
			if (this.drawer) this.drawer.disable();
			this.handleMapClick();
		}

		if (prevContext.activeTopic !== context.activeTopic)
			this.stopDrawing();

		if (prevContext.attributesToHide !== context.attributesToHide)
			this.setState({
				visibleLayers: {
					...this.state.visibleLayers,
					attributesToHide: [...context.attributesToHide],
				},
			});

		if (prevContext.isAttributeFormOpen && !this.context.isAttributeFormOpen)
			this.handleMobileScreens(false);

		if (prevState.visibleLayers !== this.state.visibleLayers)
			this.applyVisibilityFilters();
	}

	componentWillUnmount() {
		document.removeEventListener('keydown', this.stopDrawing);
	}

	stopDrawing = (event=null) => {
		if (!this.drawer)
			return;
		if (!event || event.key === "Escape" || event.key === "Esc") {
			if (event)
				event.preventDefault();
			this.props.context.set({drawOnMap: false, activeAttribute: null, isAttributeFormOpen: false});
			this.drawer.disable();
			this.handleMobileScreens(false);
		}
	}

	handleMapClick = () => {
		const { context } = this.props;
		const { activeAttribute } = context;
		if (!context.project.active) return;
		// Without geometry is handled by provider, attributes with predefined geometries are handled separately
		if (activeAttribute.has_predefined) return;
		// set layer visible
		if (activeAttribute.has_geometry && context.attributesToHide.includes(activeAttribute.token)) {
			let index = context.attributesToHide.indexOf(activeAttribute.token);
			context.set({attributesToHide: [...context.attributesToHide.slice(0, index), ...context.attributesToHide.slice(index + 1)]})
		}
		if (!this.isMoreObjectsAllowed()) return;
		const leafletFG = this._editableFG.leafletElement;

		switch (activeAttribute.geom_type) {
			case 'point':
				const icon = context.activeAttribute.icon ? createLeafletIcon(context.activeAttribute.icon) : null;
				this.drawer = icon ? new L.Draw.Marker(this.mapRef.leafletElement, {icon}) : new L.Draw.Marker(this.mapRef.leafletElement);
				this.drawer._initialLabelText = context.messages['add marker'] + '<br/>' + context.messages['or esc to cancel']
				break;
			case 'line':
				this.drawer = new L.Draw.Polyline(this.mapRef.leafletElement);
				break;
			case 'polygon':
				this.drawer = new L.Draw.Polygon(this.mapRef.leafletElement);
				break;
			default:
				console.warn('error geom type');
		}
		this.mapRef.leafletElement.locate().on('draw:created', this.createDraw);
		this.drawer.enable();
		this.handleMobileScreens(true);
	}

	handleMobileScreens = (enableDrawing) => {
		if (window.innerWidth < 768) {
			let action = enableDrawing ? closeAside : openAside;
			this.props.dispatch(action());
		}
	}

	createDraw = (e) => {
		const { context } = this.props;
		let boundaryLayer, numberOfGeomAttribute = 0, numberOfGeomTopic = 0;

		if (this.isInsideBoundary(e)) {
			try {
				e.layer.setStyle({
					color: context.activeAttribute.geom_color !== '' ? context.activeAttribute.geom_color : createHexColor(context.activeAttribute.mname),
					weight: 5,
					fillColor: context.activeAttribute.geom_color !== '' ? context.activeAttribute.geom_color : createHexColor(context.activeAttribute.mname),
					opacity: 0.7
				});
			} catch (e) {}
			this.props.openAttributeForm({uploadGeometry: () => this.uploadGeometry(e.layer), owner: true});
		} else {
			context.set({activeAttribute: context.activeAttribute});
			this.handleMapClick();
		}
	}

	handlePredefinedClick = (e) => {
		if (!this.props.context.project.active || this.state.predefinedEditing)
			return;
		if (!this.isMoreObjectsAllowed()) return;
		const { activeAttribute } = this.props.context;
		if (this.isInsideBoundary(e)) {
			if (activeAttribute.allow_resize || activeAttribute.allow_drag) {
				this.props.openAttributeForm({uploadGeometry: () => this.uploadGeometry(e.layer), owner: true});
			} else {
				this.props.openAttributeForm({uploadGeometry: () => this.uploadGeometry(e.layer, true), owner: true});
			}
		}
	}

	isMoreObjectsAllowed = () => {
		const { context } = this.props;
		let numberOfAnswersPerAttr = 0, numberOfAnswersPerTopic = 0;
		this._editableFG.leafletElement.eachLayer(layer => {
			if (layer.feature && layer.feature.properties.attribute === context.activeAttribute.token)
				numberOfAnswersPerAttr++;
			if (layer.feature.properties.topic === context.activeTopic.token)
				numberOfAnswersPerTopic += 1;
		});
		if ((context.activeAttribute.max_geom && numberOfAnswersPerAttr >= context.activeAttribute.max_geom) ||
			(context.activeTopic.max_geom && numberOfAnswersPerTopic >= context.activeTopic.max_geom)
		) {
			context.pushNotification({
				body: "you cannot add other objects",
				type: "warning"
			});
			return false;
		}
		return true;
	}

	isInsideBoundary = (e) => {
		const { context } = this.props;
		const boundary = this.boundaryFGRef.current.leafletElement.toGeoJSON();
		let outOfBoundaries = -boundary.features.length;
		boundary.features.forEach(feature => {
			if (!turf.intersect(e.layer.toGeoJSON(), feature))
				outOfBoundaries++;
		});
		if (outOfBoundaries === 0) {
			context.pushNotification({
				body: "out of boundaries",
				type: "warning"
			});
			return false;
		}
		return true;
	}

	uploadGeometry(layer, predefined=false) {
		const { context } = this.props;
		const attribute = context.activeAttribute.token;
		const data = {
			attribute,
			node: context.activeTopic.token,
			geometry: predefined ? layer.feature.uuid : JSON.stringify({
				type: "FeatureCollection",
				features: [layer.toGeoJSON()],
			}),
		};

		let promise = this.props.uploadGeometry(data);
		promise.then((response) => {
			let leafletGeoJSON = new L.GeoJSON(layer.toGeoJSON());
			leafletGeoJSON.eachLayer(l => {
				l.feature.properties = {
					attribute,
					choice: response.token,
					owner: true,
				};
				l.layerID = response.token;
				const style = context.styles[attribute];
				l.options = {
					...l.options,
					...layerOptions(style),
				};
				let leafletFG = this._editableFG.leafletElement;
				leafletFG.addLayer(l);
			});
		});

		return promise;
	}

	handleWhichAnswersAreShownChange = (layer) => {
		const { whichAnswersAreShown } = this.state;
		const show = Object.keys(layer).find(key => layer[key].checked);
		const owner = show === 'mine';
		const toprated = show === 'topRated';
		this.setState({
			whichAnswersAreShown: {
				...whichAnswersAreShown,
				...layer,
			},
			visibleLayers: {
				...this.state.visibleLayers,
				owner,
				toprated,
			}
		});
	}

	applyVisibilityFilters = () => {
		let leafletFG = this._editableFG.leafletElement;
		const { owner, attributesToHide, toprated } = this.state.visibleLayers;
		leafletFG.eachLayer(layer => {
			let { properties } = layer.feature;
			if ((owner && !properties.owner) || (toprated && !properties.toprated) || attributesToHide.includes(properties.attribute)) {
				layer.getElement().style.display = 'none';
			} else {
				layer.getElement().style.display = 'block';
			}
		});
	}

	handleGeojsonRequest = (query=null) => {
		let leafletFG = this._editableFG.leafletElement;
		leafletFG.clearLayers();
		this.props.getGeoJSON(query).then((geojson) => {
			if (!geojson.features)
				return;
			let leafletGeoJSON = new L.GeoJSON(geojson);

			leafletGeoJSON.eachLayer(layer => {
				layer.layerID = layer.feature.properties.choice;
				const style = this.props.styles[layer.feature.properties.attribute];
				layer.options = {
					...layer.options,
					...layerOptions(style),
				};
				leafletFG.addLayer(layer);
			});
			this.applyVisibilityFilters();

		}).catch(err => console.warn(err));
	}

	_onFeatureGroupReady = (reactFGref) => {
		if (!reactFGref) return;
		this._editableFG = reactFGref;
		this.handleGeojsonRequest();
	}

	_onPredefinedFGReady = (reactPredefinedFGRef) => {
		if (!reactPredefinedFGRef) return;
		this._predefinedFG = reactPredefinedFGRef;
		const { activeAttribute } = this.props.context;
		if (!activeAttribute.predefined)
			return;
		let leafletGeoJSON = new L.GeoJSON(activeAttribute.predefined, {draggable: true});
		let leafletFG = reactPredefinedFGRef.leafletElement;
		leafletGeoJSON.eachLayer(layer => {
			layer.options = {
				...layer.options,
				...predefinedLayerOptions(activeAttribute),
			};
			layer.dragging.disable();
			leafletFG.addLayer(layer);
		});
	}

	_onMapReady = (mapRef) => {
		if (!mapRef)
			return;
		this.mapRef = mapRef;
	}

	handleBboxChange = (e, scope) => {
		let filter=null;
		switch(scope) {
			case 'edit':
				e.layers.eachLayer(layer => {
					filter = toWKT(layer, 'EPSG:4326');
				});
				break;

			case 'create':
				filter = toWKT(e.layer, 'EPSG:4326');
				break;

			case 'start':
				break;

			default:
				break;
		}

		this.props.context.set({filter});
		this.handleGeojsonRequest();
	}

	handleBboxClear = () => {
		const layerContainer = this.bboxEditControlRef.current.leafletElement.options.edit.featureGroup;
		const layers = layerContainer._layers;
		Object.keys(layers).forEach(id => {
			const layer = layers[id];
			layerContainer.removeLayer(layer);
		});
	}

	render() {
		const { openAttributeForm, handleDelete } = this.props;
		const { center, zoom, leafletPopup, whichAnswersAreShown } = this.state;
		const { attributes, project, topics, messages, activeAttribute, category, drawOnMap, role } = this.props.context;
		if (!center || !zoom) return null;

		return (
			<VisibilitySensor resizeCheck={true} onChange={() => {this.mapRef.leafletElement.invalidateSize()}}>
				<LeafletMap
					className={this.props.className || undefined}
					ref={this._onMapReady}
					center={center}
					zoom={zoom}
				>
					<TileLayer
						url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
						attribution="&copy; <a href=&quot;http://osm.org/copyright&quot;>OpenStreetMap</a> contributors"
					/>
					<FeatureGroup ref={this._onFeatureGroupReady} onClick={(e) => this.setState({leafletPopup: e.layer})}>
						<Popup>
							{ leafletPopup &&
								<PopupContent
									title={attributes.find(attr => attr.token === leafletPopup.feature.properties.attribute).name}
									onDetailsClick={() => {
										let { attribute, choice } = leafletPopup.feature.properties;
										openAttributeForm({
											topic: topics.find(topic => topic.token === attributes.find(attr => attr.token === attribute).node),
											attribute: attributes.find(attr => attr.token === attribute),
											choice,
											owner: leafletPopup.feature.properties.owner,
										});
									}}
									onDeleteClick={() => handleDelete(leafletPopup.feature.properties.choice, () => {
										const token = leafletPopup.feature.properties.choice;
										this.setState({leafletPopup: null});
										const leafletFG = this._editableFG.leafletElement;
										leafletFG.eachLayer(layer => {
											if (layer.feature.properties.choice === token)
												leafletFG.removeLayer(layer);
										})
									})}
									messages={messages}
									isDeleteEnabled={project.active}
									choice={leafletPopup.feature.properties.choice}
									hasSocial={project.is_public}
									isOwner={leafletPopup.feature.properties.owner}
								/>
							}
						</Popup>
					</FeatureGroup>
					{ (drawOnMap && activeAttribute && activeAttribute.has_predefined) &&
						<FeatureGroup
							ref={this._onPredefinedFGReady}
							onClick={this.handlePredefinedClick}
						>
							{ (activeAttribute.allow_resize || activeAttribute.allow_drag) &&
								<EditControl
									position="topright"
									draw={{rectangle: false, marker: false, circle: false, circlemarker: false, polygon: false, polyline: false}}
									edit={{
										remove: false,
										poly: {allowIntersection: false},
										edit: {
											selectedPathOptions: {moveMarkers: true, editing: activeAttribute.allow_resize, dragging: activeAttribute.allow_drag}
										},
									}}
									onEditStart={() => this.setState({predefinedEditing: true})}
									onEditStop={() => this.setState({predefinedEditing: false})}
								/>
							}
						</FeatureGroup>
					}
					<FeatureGroup ref={this.boundaryFGRef}/>
					<CustomLayersControl
						type="radio"
						layers={whichAnswersAreShown}
						onLayerChange={this.handleWhichAnswersAreShownChange}
						label={project.public_date ? `${project.is_public ? messages['answers was shown'] || 'Answers was shown' : messages['answers will be shown'] || 'Answers will be shown'} ${moment(project.public_date.substr(0,10), "YYYY-MM-DD").calendar()}.` : ''}
						disabled={role === roles.AUTHORIZED && !project.is_public}
					/>
					{ (role !== roles.AUTHORIZED || project.is_public) &&
						<ProfileControl
							profile={category.consultation}
							onSubmit={this.handleGeojsonRequest}
						/>
					}
					{ (project && project.wms_services && project.wms_services !== '[]') &&
						<LayersControl position="bottomleft">
							{ JSON.parse(project.wms_services).map((wms, index) =>
								<LayersControl.Overlay key={`wms_${index}`} checked name={wms.title}>
									<WMSTileLayer format="image/png" transparent layers={wms.layers} url={wms.WMSurl}/>
								</LayersControl.Overlay>
							)}
						</LayersControl>
					}
					{ role !== roles.AUTHORIZED &&
						<FeatureGroup>
							<EditControl
								ref={this.bboxEditControlRef}
								position="topleft"
								draw={{rectangle: false, marker: false, circle: false, circlemarker: false, polygon: {allowIntersection: false}, polyline: false}}
								edit={{
									remove: true,
									poly: {allowIntersection: false},
									edit: {
										selectedPathOptions: {editing: true}
									},
								}}
								onEdited={(e) => this.handleBboxChange(e, 'edit')}
								onCreated={(e) => this.handleBboxChange(e, 'create')}
								onDeleted={(e) => this.handleBboxChange(e, 'delete')}
								onDrawStart={this.handleBboxClear}
							/>
						</FeatureGroup>
					}
				</LeafletMap>
			</VisibilitySensor>
		);
	}
}

export default props => (
	<QuestionnaireContext>
		{context => <Map {...props} context={context} />}
	</QuestionnaireContext>
);
