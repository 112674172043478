import React, { Component } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { Row, Col, Modal, ModalHeader, ModalBody, Button } from 'reactstrap';

import { requestData } from 'core/ducks/list';
import { getData, deleteData, postData } from 'core/ducks/update';
import { initContext, setContext } from 'core/ducks/context';
import { pushNotification } from 'core/ducks/notifications';
import { toggleModal } from 'core/ducks/ui/modal';
import { Loading } from 'core/components';
import Alert from 'core/views/modals/alert';
import { ErrorPage } from 'core/views/pages';
import * as roles from 'core/model/roles';
import { ExportToCSV } from 'core/model/lib';
import { fetchAppProfile } from '../../ducks/categoriesprofile';
import { createHexColor } from '../../model/lib';
import { Answers, AttributeForm, ConsultationUserProfileForm } from '../modals';
import Map from '../../components/map';

import T from 'modules/i18n';

const Context = React.createContext();

export const QuestionnaireContext = Context.Consumer;

class Questionnaire extends Component {

	constructor(props) {
		super(props);
		this.state = {
			consultationProfile: {},
			category: [],
			project: [],
			topics: [],
			attributes: [],

			activeTopic: null,
			activeAttribute: null,

			ready: -2,
			styles: {},
			isConsultationFormOpen: false,
			isAttributeFormOpen: false,
			isChooseModeModalOpen: false,
			showAnswers: false,
			attributeFormProps: {},

			answersWOGeom: [],
			attributesToHide: [],
			isAnsweringEnabled: false,
			drawOnMap: false,
			filter: null,

			httpStatus: 200,
		};

		this.actions = bindActionCreators({toggleModal}, props.dispatch);
	}

	setContext = (newContext) => {
		this.setState({...newContext});
	}

	componentDidMount() {
		this.props.dispatch(initContext({...this.state, set: this.setContext}));
		let promise;
		if (this.props.role === roles.AUTHORIZED) {
			promise = this.props.dispatch(fetchAppProfile());
		} else {
			promise = this.props.dispatch(fetchAppProfile(true, this.props.match.params.category));
		}

		promise.then(() => {
			this.props.dispatch(requestData('topic', `node/project/${this.props.match.params.project}`));
			this.props.dispatch(requestData('attribute', `attribute/project/${this.props.match.params.project}`));
		});
	}

	componentDidUpdate(prevProps, prevState) {
		if (prevState !== this.state) {
			const newState = {};
			Object.keys(this.state).forEach(key => {
				if (prevState[key] !== this.state[key])
					newState[key] = this.state[key];
			});
			this.props.dispatch(setContext(newState));
		}

		if (prevProps.categoriesProfile.pending && !this.props.categoriesProfile.pending) {
			const category = Object.values(this.props.categoriesProfile.enrolled).find(item => item.mname === this.props.match.params.category);
			if (category) {
				const project = category.projects.find(item => item.mname === this.props.match.params.project);
				const newState = {consultationProfile: category.consultation, category};
				if (project) {
					project.legislation = Array.isArray(project.legislation) ? project.legislation : JSON.parse(project.legislation);
					newState.project = project;
				} else {
					newState.httpStatus = 404;
					newState.ready = 0;
				}
				this.setState({...newState});
			} else {
				this.setState({httpStatus: 404, ready: 0});
			}
		}

		if ((prevProps.topics.pending && !this.props.topics.pending) || (prevProps.topics.refreshing && !this.props.topics.refreshing))
			if (this.state.httpStatus === 200) {
				if (this.props.topics.status === 200) {
					this.setState({topics: this.props.topics.data, ready: this.state.ready + 1});
				} else {
					this.setState({httpStatus: this.props.topics.status, ready: 0});
				}
			}

		if ((prevProps.attributes.pending && !this.props.attributes.pending) || (prevProps.attributes.refreshing && !this.props.attributes.refreshing))
			if (this.state.httpStatus === 200 && this.props.attributes.status === 200) {
				this.setState({
					attributes: this.props.attributes.data,
					styles: this.props.attributes.data.reduce((obj, elem) => ({
						...obj,
						[elem.token]: {
							color: elem.geom_color !== '' ? elem.geom_color : createHexColor(elem.mname),
							icon: elem.icon
						},
					}), {}),
					ready: this.state.ready + 1,
					httpStatus: this.props.attributes.status,
				});
			}

		if (prevState.ready !== 0 && this.state.ready === 0 && this.state.httpStatus === 200) {
			const {category, project} = this.state;
			if (!category.has_answered)
				this.setState({
					isConsultationFormOpen: true
				});
		}

		if (prevState.activeAttribute !== this.state.activeAttribute && this.state.activeAttribute) {
			if (this.props.role !== roles.AUTHORIZED) {
				this.setState({showAnswers: true});
			} else {
				const { project } = this.state;
				if (project.active && project.is_public) {
					this.setState({isChooseModeModalOpen: true});
				} else if (!project.active && project.is_public) {
					this.setState({showAnswers: true});
				} else if (project.active && !project.is_public) {
					this.setState({isAnsweringEnabled: true});
				}
			}
		}

		if (!prevState.isAnsweringEnabled && this.state.isAnsweringEnabled) {
			if (!this.state.activeTopic.has_geometry && !this.state.activeAttribute.has_geometry) {
				this.setState({isAnsweringEnabled: false});
				this.handleAttrWOGeom();
			} else {
				this.setState({drawOnMap: true, isAnsweringEnabled: false});
			}
		}

	}

	handleAttrWOGeom = () => {
		const { attributes, topics, activeAttribute } = this.state;
		const currentAnswer = Object.values(this.state.answersWOGeom).find(properties => properties.attribute === activeAttribute.token);
		if (currentAnswer) {
			const topicToken = attributes.find(attr => attr.token === currentAnswer.attribute).node;
			this._openAttributeForm({
				topic: topics.find(attr => attr.token === topicToken),
				attribute: attributes.find(attr => attr.token === currentAnswer.attribute),
				choice: currentAnswer.choice,
				owner: true,
			})
		} else {
			this._openAttributeForm({uploadGeometry: () => this.postChoice(), owner: true});
		}
	}

	postChoice() {
		const { activeAttribute, activeTopic } = this.state;
		const attribute = activeAttribute.token;
		const data = {
			attribute,
			node: activeTopic.token,
		};

		let promise = this.props.dispatch(postData('data/scope/attribute', data, false));
		promise.then(response => {
			this.setState({answersWOGeom: [
				...this.state.answersWOGeom,
				{...data, choice: response.token},
			]});
		});

		return promise;
	}

	_getGeoJSON = (query) => {
		const promise = new Promise((resolve, reject) => {
			let url = `data/scope/geojson/project/${this.state.project.token}`;
			if (query) url += `/profile/${query}`;
			if (this.state.filter)
				url += `/within/${this.state.filter}`
			this.props.dispatch(
				getData(url)
			).then(geojson => {
				if (geojson.features)
					this.setState({answersWOGeom: geojson.features.filter(feature => !feature.geometry).map(feature => feature.properties)});
				resolve(geojson);
			}).catch(err => {
				console.warn(err);
				reject(err);
			});
		});
		return promise;
	}

	_openAttributeForm = (attributeFormProps) => {
		this.setState({
			isAttributeFormOpen: true,
			attributeFormProps,
		});
	}

	handleDelete = (token, callback) => {
		this.actions.toggleModal(true,
			<Alert
				toggle={() => this.actions.toggleModal()}
				title="drop confirm"
				message="do you wish to continue"
				onConfirm={() => {
					this.props.dispatch(deleteData(`data/scope/attribute/choice/${token}`))
						.then(() => callback());
				}}
			/>
		);
	}

	handleExport = (token) => {
		if (this.props.role === roles.AUTHORIZED)
			return;
		const csv = new ExportToCSV('export.csv');
		this.props.dispatch(
			getData(`export/project/${token}/output_format/csv`, true, 'blob')
		).then(data => csv.createLink(data));
	}

	render() {
		const {
			ready, styles, isAttributeFormOpen, project, attributeFormProps, isConsultationFormOpen, isChooseModeModalOpen, category, showAnswers, httpStatus
		} = this.state;
		const { isAsideOpen } = this.props;
		const { messages } = this.props.i18n || {messages: {}};

		if (ready !== 0)
			return (<Loading/>);
		if (httpStatus !== 200)
			return (<ErrorPage status={httpStatus}/>);
		return (
			<div className="ppcity-questionnaire animated fadeIn">
				<Context.Provider
					value={{
						...this.state,
						messages,
						isAsideOpen,
						openAttributeModal: (layer) => this._openAttributeForm({uploadGeometry: () => this.uploadGeometry(layer)}),
						pushNotification: (content) => this.props.dispatch(pushNotification(content)),
						set: this.setContext,
						role: this.props.role,
					}}
				>
					<Map
						className="full-content"
						getGeoJSON={this._getGeoJSON}
						styles={styles}
						openAttributeForm={this._openAttributeForm}
						handleDelete={this.handleDelete}
						uploadGeometry={(data) => {
							return this.props.dispatch(postData('data/scope/attribute', data, false));
						}}
						dispatch={this.props.dispatch}
					/>
					{ showAnswers &&
						<Answers toggle={() => this.setState({showAnswers: false, activeAttribute: null})}/>
					}
				</Context.Provider>
				{ this.props.role !== roles.AUTHORIZED &&
					<div style={{position: 'absolute', zIndex: 401, right: '15px', top: '15px'}}>
						<a
							href={`/api/export/project/${project.mname}/output_format/geojson`}
							target="_blank"
							rel="noopener noreferrer"
							title="GeoJSON"
						>
							<i role="button" style={{fontSize: '140%'}} className="fa fa-code"/>
						</a>
						<br/>
						<i
							role="button"
							style={{fontSize: '140%'}}
							className="fa fa-file-excel-o mt-2"
							onClick={() => this.handleExport(project.token)}
							title="CSV"
						/>
					</div>
				}
				{ isConsultationFormOpen &&
					<ConsultationUserProfileForm
						isOpen={isConsultationFormOpen}
						toggle={() => this.setState({isConsultationFormOpen: false})}
						profile={this.state.consultationProfile}
						category={category.token}
						data={[]}
					/>
				}
				{ isAttributeFormOpen &&
					<AttributeForm
						isOpen={isAttributeFormOpen}
						toggle={() => this.setState({isAttributeFormOpen: false, attributeFormProps: {}, activeAttribute: null, drawOnMap: false})}
						topic={this.state.activeTopic}
						attribute={this.state.activeAttribute}
						edit={project.active}
						{...attributeFormProps}
					/>
				}
				{ isChooseModeModalOpen &&
					<Modal isOpen={isChooseModeModalOpen} className="modal-sm">
						<ModalHeader toggle={() => this.setState({isChooseModeModalOpen: false})}><T>choose action</T></ModalHeader>
						<ModalBody>
							<Row>
								<Col>
									<Button color="success" onClick={() => this.setState({isChooseModeModalOpen: false, isAnsweringEnabled: true})}>
										<T>add answer</T>
									</Button>
								</Col>
								<Col>
									<Button color="primary" onClick={() => {this.setState({isChooseModeModalOpen: false, showAnswers: true})}}>
										<T>see answers</T>
									</Button>
								</Col>
							</Row>
						</ModalBody>
					</Modal>
				}
			</div>
		);
	}
}

const mapStateToProps = (state) => ({
	categoriesProfile: state.categoriesprofile,
	topics: state.list.topic,
	attributes: state.list.attribute,
	i18n: state.i18n,
	isAsideOpen: !state.ui.menu.hideAsideMenu,
	role: state.profile.user.role,
});

Questionnaire = connect(mapStateToProps)(Questionnaire);

export default Questionnaire;
