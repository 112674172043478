import React, { Component } from 'react';
import { connect } from 'react-redux';
import {
	ModalHeader, Modal, ModalBody, Button, Form, FormGroup, Label,
	Nav, NavItem, NavLink, TabContent, TabPane
} from 'reactstrap';

import { requestData } from 'core/ducks/list';
import { getData } from 'core/ducks/update';
import { Input } from 'input-fields';
import { uploadData } from 'core/ducks/upload';

import T from 'modules/i18n';

const types = {
	radio: 'radio',
	checkbox: 'checkbox',
	document: 'file',
	date: 'date',
	text: 'textarea',
	string: 'text',
	photo: 'file',
	number: 'number',
	resource: '',
	library: 'library',
};

class AttributeForm extends Component {

	constructor(props) {
		super(props);
		this.emptyValues = {};
		this.initialValues = {...this.emptyValues};
		this.state = {
			activeTab: null,
			activeIndex: 0,
			values: { ...this.initialValues },
			choice: props.choice,
			pending: true,
			method: {}
		};

		this.handleSubmit = this.handleSubmit.bind(this);
	}

	componentDidMount() {
		this.props.dispatch(requestData('variable', `variable/attribute/${this.props.attribute.token}/case/map`));
	}

	componentDidUpdate(prevProps) {
		const { variables } = this.props;
		if (((prevProps.variables.pending && !variables.pending) || (prevProps.variables.refreshing && !variables.refreshing)) && variables.status === 200) {
			const { data } = this.props.variables;
			if ( data.length === 0 ) {
				if (typeof this.props.uploadGeometry === 'function')
					this.props.uploadGeometry();
				this.props.toggle();
			} else {
				this.emptyValues = data.reduce((obj, variable) => ({
					...obj,
					[variable.token]: Object.keys(variable.variable_type).reduce((obj2, key) => ({
						...obj2,
						[key]: {value: '', resource: null}
					}), {})
				}), {});
				this.initialValues = { ...this.emptyValues };
				this.setState({
					values: {...this.initialValues},
					pending: false,
					activeTab: data[0].token,
					method: data.reduce((obj, variable) => ({
						...obj,
						[variable.token]: 'post',
					}), {}),
				});
				if (this.state.choice)
					this.fetchData(this.state.choice);
			}
		}
	}

	fetchData(choice = this.state.choice) {
		this.props.dispatch(
			getData(`data/scope/answers/choice/${choice}`)
		).then(data => {
			const tokens = Object.keys(data);
			const activeIndex = tokens.length;
			this.initialValues = {...this.state.values, ...data};
			this.setState({
				values: {...this.initialValues},
				method: {
					...this.state.method,
					...Object.keys(data).reduce((obj, key) => ({
						...obj,
						[key]: 'put',
					}), {}),
				},
				activeTab: this.props.variables.data[activeIndex] ? this.props.variables.data[activeIndex].token : tokens[activeIndex - 1],
				activeIndex: this.props.variables.data[activeIndex] ? activeIndex : activeIndex - 1,
			});
		})
		.catch(err => console.warn(err));
	}

	handleSubmit(event) {
		event.preventDefault();
		if (!this.state.choice) {
			if (typeof this.props.uploadGeometry === 'function') {
				this.props.uploadGeometry().then(choice => {
					this.setState({choice: choice.token});
					this.uploadData(choice.token);
				});
			} else {
				console.warn('One of choice / uploadGeometry should be provided.');
			}
		} else {
			this.uploadData(this.state.choice);
		}
	}

	uploadData(choice) {
		const { values, activeTab, activeIndex, method } = this.state;
		const variable = activeTab;
		const formData = new FormData();
		if (method[variable] === 'put') {
			formData.append('_method', 'PUT');
		} else {
			formData.append('variable', variable);
			formData.append('choice', choice);
		}
		let changed = false;
		Object.keys(values[variable]).forEach((type, index) => {
			const current = values[variable][type];
			const initial = this.initialValues[variable][type];
			if (method[variable] === 'post' || current.resource !== initial.resource || current.value !== initial.value) {
				changed = true;
				formData.append(`values[${index}][variable_type]`, type);
				if (current.resource  && current.resource.name) {
					formData.append(`values[${index}][value]`, null);
					formData.append(`resource_${index}`, current.resource);
				} else {
					formData.append(`values[${index}][value]`, current.value);
				}
			}
		});
		if (!changed)
			return;
		const url = method === 'post' ? 'data/scope/variable' : `data/scope/variable/choice/${choice}/variable/${variable}`;
		this.props.dispatch(uploadData(url, formData))
			.then(() => {
				if (this.props.variables.data[activeIndex + 1]) {
					this.fetchData();
				} else {
					this.props.toggle();
				}
			})
			.catch(err => console.warn(err));
	}

	handleChange(event, variable) {
		const { type, name, value, files } = event.target;
		this.setState({
			values: {
				...this.state.values,
				[variable]: {
					...this.state.values[variable],
					[name]: {
						value,
						resource: type==='file' ? files[0] : null
					},
				}
			}
		});
	}

	toggleTab = (activeTab) => {
		if (activeTab === this.state.activeTab)
			return;
		this.setState({
			activeTab,
			activeIndex: this.props.variables.data.findIndex(variable => variable.token === activeTab),
		});
	}

	render() {
		const { isOpen, toggle, attribute, topic, variables, owner } = this.props;
		const { messages } = this.props.i18n || {messages: {}};
		const { activeTab, values, pending } = this.state;

		console.log(this.props.edit);
		console.log(this.props.owner);

		if (pending)
			return null;

		return (
			<Modal isOpen={isOpen} toggle={toggle} className="modal-lg" scrollable={true}>
				<ModalHeader toggle={toggle} className="text-center">
					{topic.title}{attribute.name ? `: ${attribute.name}` : ''}
				</ModalHeader>
				<img
					src={this.props.attribute.icon}
					alt={this.props.attribute.icon_alt}
					className="float-left image mx-auto my-2 p-2"
					style={{maxHeight: '4em'}}
				/>
				<ModalBody>
					<Nav tabs>
						{ variables.data.map((variable, index) => (
							<NavItem key={`navitem_${variable.token}`}>
								<NavLink
									className={activeTab === variable.token ? 'active' : ''}
									onClick={() => this.toggleTab(variable.token)}
								>
									<span className="text-capitalize"><T>question</T>{' '}{index + 1}</span>
								</NavLink>
							</NavItem>
						))}
					</Nav>
					<TabContent activeTab={activeTab}>
						{ variables.data.map((variable, index) => (
							<TabPane tabId={variable.token} key={`tabpane_${variable.token}`}>
								<Form className="w-100" onSubmit={this.handleSubmit}>
									<p>{variable.description}</p>
									{ Object.keys(variable.variable_type).map(type => {
										let { value, resource } = values[variable.token][type] || {value: '', resource: null};
										let current = variable.variable_type[type];
										return (
											<FormGroup key={`formgroup_${variable.token}_${type}`}>
												{ current.label && <Label>{current.label}</Label> }
												{ (resource && !resource.name && type === 'document') &&
													<div><a href={resource} download target='_blank' rel="noopener noreferrer"><T>file</T></a></div>
												}
												{ (resource && !resource.name && type === 'photo') &&
													<div><img className="w-100 h-auto p-4" src={resource} alt="user image"/></div>
												}
												<Input
													id={variable.token + type}
													type={types[type]}
													readOnly={!this.props.edit || !this.props.owner}
													required={current.required}
													options={current.choices}
													items={current.items || undefined}
													allowmultiple={current.allowMultiple || undefined}
													name={type}
													placeholder={current.placeholder || undefined}
													text={current.choices}
													value={value}
													onChange={(e) => this.handleChange(e, variable.token)}
													messages={messages}
												/>
											</FormGroup>
										);
									})}
									{ (this.props.edit && this.props.owner) &&
										<FormGroup className="text-right" style={{marginTop: '2.5em'}}>
											<Button color="success" type="submit"><T>submit</T></Button>
										</FormGroup>
									}
								</Form>
							</TabPane>
						))}
					</TabContent>
				</ModalBody>
			</Modal>
		);
	}
}

const mapStateToProps = (state) => ({
	variables: state.list.variable,
	i18n: state.i18n,
});

AttributeForm = connect(mapStateToProps)(AttributeForm);

export default AttributeForm;
